#float_menu {
  transition: transform 0.4s ease;
}

.menu--open {
  #float_menu {
    transform: translateX(calc(-1 * var(--s-side-menu-width)));
  }
}

#float_menu {
  transition: top 0.3s ease, transform 0.4s ease;
}

#site {
  position: relative;
  &:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 86px;
    opacity: 0;
    background-color: var(--c-main-bg);
    box-shadow: 0px 0px 4px var(--c-dark);
    transform: translateY(-100%);
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
}
.menu_sticky {
  #float_menu {
    top: 27px;
  }
  #site:after {
    opacity: 1;
    transform: translateY(0);
  }
}

[data-menu] {
  position: fixed;
  top: 0;
  right: calc(-1 * var(--s-side-menu-width));
  display: block;
  width: var(--s-side-menu-width);
  height: 100vh;
  background-color: var(--c-main);
  padding: 3rem;
  transition: right 0.4s ease;
  z-index: 999;
  @include breakpoint(mobile, min) {
    padding: 4rem;
  }
  @include breakpoint(tablet-p, min) {
    padding: 5rem;
  }
  .menu_item {
    display: block;
    margin-bottom: 2.5rem;
    color: var(--c-main-bg);
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    &:hover {
      color: var(--c-accent);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.--open {
    right: 0;
  }
  .menu_socials {
    margin-top: 3rem;
    padding-top: 1.25rem;
    border-top: 2px solid var(--c-grey);
    .btn_icon {
      width: 36px;
      height: 36px;
      &:before,
      &:after {
        border-radius: 10px;
      }
      &:hover,
      &:focus {
        background-color: var(--c-accent);
        border-radius: 10px;
      }
    }
  }
}

[data-menu-toggle] {
  transition: transform 0.4s ease;
  .bars {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin-left: 4px;
    width: 30px;
    height: 24px;
    @include breakpoint(mobile, min) {
      margin-left: 8px;
    }
  }
  .bar,
  .bar:before,
  .bar:after {
    position: absolute;
    display: block;
    width: 40px;
    height: 4px;
    border-radius: 4px;
    background-color: var(--c-btn-bg);
  }
  .bar {
    top: 50%;
    margin-top: -2px;
    transform: scale(0.7);
    &:before,
    &:after {
      content: '';
      transition: transform 0.15s ease;
    }
    &:before {
      top: -10px;
    }
    &:after {
      top: 10px;
    }
  }
  &.--open {
    transform: rotate(-180deg);
    .bar {
      &:before {
        transform: translate3d(-8px, 0, 0) rotate(-45deg) scaleX(0.7);
      }
      &:after {
        transform: translate3d(-8px, 0, 0) rotate(45deg) scaleX(0.7);
      }
    }
  }
}
