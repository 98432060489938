.timeline {
  position: relative;
  .item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1.2em;
    .date,
    .infos {
      flex: 0;
      flex-grow: 0;
      flex-shrink: 0;
    }
    .date {
      flex-basis: 100%;
      max-width: 100%;
      padding-top: 12px;
      padding-right: 1.2rem;
      padding-left: 0;
      margin-bottom: 18px;
      @include breakpoint(tablet-p, min) {
        flex-basis: 25%;
        max-width: 25%;
        padding-left: 1.2rem;
      }
      time,
      .duree {
        display: inline-block;
        text-align: left;
        @include breakpoint(tablet-p, min) {
          display: block;
          padding-right: 1rem;
          text-align: right;
        }
      }
      time {
        padding-right: 3px;
        text-transform: uppercase;
        font-weight: 600;
        @include breakpoint(tablet-p, min) {
          padding-right: 1rem;
        }
      }
    }
    .infos {
      position: relative;
      flex-basis: 100%;
      max-width: 100%;
      display: block;
      padding: 12px 15px;
      color: var(--c-main-bg);
      background-color: var(--c-main);
      border: none;
      text-decoration: none;
      @include breakpoint(tablet-p, min) {
        flex-basis: 75%;
        max-width: 75%;
      }
      @include breakpoint(tablet-l, min) {
        flex-basis: 50%;
        max-width: 50%;
      }
      &:before,
      &:after {
        content: '';
        position: absolute;
      }
      &:before {
        top: -12px;
        left: 12px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 12px 12px 12px;
        border-color: transparent transparent var(--c-main) transparent;
        @include breakpoint(tablet-p, min) {
          top: 12px;
          left: -12px;
          border-width: 12px 12px 12px 0;
          border-color: transparent var(--c-main) transparent transparent;
        }
      }
      &:after {
        left: 0;
        bottom: 0;
        display: block;
        width: 100%;
        height: 0;
        background-color: var(--c-accent);
        transition: height 0.2s ease-in-out;
      }
      .icon,
      .texte {
        display: inline-block;
        vertical-align: middle;
        font-size: 0;
      }
      .icon {
        width: 50px;
        @include breakpoint(tablet-p, min) {
          width: 64px;
        }
        img {
          display: block;
          max-width: 100%;
          height: auto;
          margin: 0 auto;
          padding-right: 1rem;
          @include breakpoint(tablet-p, min) {
            padding-right: 2.4rem;
          }
        }
      }
      .texte {
        width: calc(100% - 93px);
        text-align: left;
        .titre {
          margin-top: 0;
          margin-bottom: 5px;
          font-size: 1.7rem;
          font-weight: 700;
          @include breakpoint(tablet-p, min) {
            font-size: 1.9rem;
          }
        }
        .lieu,
        .diplome {
          margin: 0;
          font-size: 1.4rem;
          @include breakpoint(tablet-p, min) {
            font-size: 1.6rem;
          }
        }
        .diplome {
          margin-top: 5px;
          font-style: italic;
        }
      }
      .details {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
        display: block;
        width: 32px;
        height: 32px;
        border: 2px solid var(--c-main-bg);
        border-radius: 50%;
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          background-color: var(--c-main-bg);
          transition: all 0.2s ease-in-out;
        }
        &:before {
          width: 15px;
          height: 2px;
        }
        &:after {
          width: 2px;
          height: 15px;
        }
      }
      &:hover:after,
      &:focus:after {
        height: 3px;
      }
      &:hover .details:before,
      &:focus .details:before {
        width: 22px;
      }
      &:hover .details:after,
      &:focus .details:after {
        height: 22px;
      }
    }
    &.current .infos {
      background-color: var(--c-main-bg);
      color: var(--c-main);
    }
    &.current .infos:before {
      border-color: transparent transparent var(--c-main-bg) transparent;
    }
    &.current .infos .icon {
      filter: invert(100%);
    }
    &.current .infos .details {
      border-color: var(--c-main);
    }
    &.current .infos .details:before,
    &.current .infos .details:after {
      background-color: var(--c-main);
    }
  }
}

[data-color-mode='light'] {
  .timeline .item {
    .infos .icon {
      filter: invert(100%);
    }
    &.current .infos .icon {
      filter: invert(0);
    }
  }
  .popup_item .popup_inner {
    .top {
      .intro .icon img {
        filter: invert(100%);
      }
      .popup_logo img {
        padding: 10px;
      }
    }
    .description {
      margin-top: 2rem;
    }
  }
}

body.--with-overlay {
  position: relative;
  user-select: none;
  pointer-events: none;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999;
  }
  #popup_close {
    display: block;
  }
}

#popup_close {
  display: none;
  position: fixed;
  top: 20px;
  right: 19px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  color: #fff;
  z-index: 999999;
  cursor: pointer;
  outline: none;
  pointer-events: auto;
  @include breakpoint(tablet-p, min) {
    top: 25px;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    background-color: #fff;
    transition: all 0.2s ease-in-out;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
    width: 35px;
    height: 2px;
  }
  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
    width: 2px;
    height: 35px;
  }
  &:hover,
  &:focus {
    &:before {
      width: 27px;
    }
    &:after {
      height: 27px;
    }
  }
  .texte {
    position: absolute;
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 15px;
  }
}

.popup_item {
  display: none;
  flex-flow: row wrap;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  min-width: 280px;
  height: calc(100% - 140px);
  z-index: 999999;
  .popup_inner {
    flex: 1;
    padding: 20px;
    background-color: var(--c-main);
    color: var(--c-main-bg);
    max-height: 100%;
    overflow-y: auto;
    user-select: text;
    pointer-events: auto;
    @include breakpoint(tablet-p, min) {
      padding: 20px 40px;
    }
    .top {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 15px;
      .intro {
        flex: 1;
        .icon,
        .texte {
          display: inline-block;
          vertical-align: middle;
          text-align: left;
        }
        .icon {
          border-radius: 50%;
          border: 2px solid var(--c-main-bg);
          padding: 10px;
          width: 50px;
          margin: 0 auto;
        }
        .texte {
          margin-left: 10px;
          width: calc(100% - 65px);
        }
        .titre {
          margin-top: 0;
          margin-bottom: 0;
        }
        .lieu {
          margin-top: 5px;
          margin-bottom: 0;
          a {
            text-decoration: none;
            color: var(--c-accent);
          }
        }
      }
      .popup_logo {
        width: 250px;
        height: 75px;
        text-align: right;
        .popup_logo_inner {
          position: relative;
          height: 100%;
          max-width: 100%;
        }
        img {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: auto;
          height: auto;
          max-height: 100%;
          max-width: inherit;
          padding: 10px 0;
          background-color: var(--c-white);
        }
      }
    }
    .description {
      margin: 1rem 0 1.5rem;
      padding: 18px;
      background-color: var(--c-grey);
      line-height: 1.4;
      color: var(--c-light-text);
    }
    .taches {
      h3 {
        margin-top: 12px;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 16px;
        border-bottom: 2px solid var(--c-main-bg);
        display: inline-block;
      }
      ul {
        margin-top: 0;
        padding-left: 0;
        li {
          list-style: none;
          line-height: 1.5;
          &:before {
            content: '+\2002';
            font-weight: 500;
          }
        }
      }
    }
  }
}
