@import '_normalize';
@import '_variables';
@import '_reset';
@import '_mixins';
@import '_button';
@import '_menu';
@import '_skills.scss';
@import '_parcours.scss';
@import '_realisations';
@import '../../node_modules/flickity/css/flickity.css';

.sr-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

html {
  font-size: 62.5%;
}

body {
  background-color: var(--c-main-bg);
  background-image: var(--p-main);
  color: var(--c-main);
  font-family: var(--f-main);
  font-size: 1.6rem;
}

#site {
  padding: 0 20px;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--f-heading);
  font-weight: 500;
}

.logo {
  position: relative;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
  &:after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    display: block;
    width: 50px;
    height: 4px;
    background-color: var(--c-accent);
  }
}

#float_logo {
  position: fixed;
  top: 20px;
  left: 15px;
  height: 10rem;
  transition: top 0.3s linear, transform 0.4s ease;
  z-index: 99;
  cursor: pointer;
  @include breakpoint(mobile, min) {
    top: 11px;
  }
  &.hide {
    top: -10rem;
    .site__name {
      font-size: 0.875rem;
      @include breakpoint(tablet-p, min) {
        font-size: 1.25rem;
      }
      @include breakpoint(tablet-p, min) {
        font-size: 1.5rem;
      }
    }
    .site__position {
      font-size: 1.2rem;
      @include breakpoint(mobile, min) {
        font-size: 1.4rem;
      }
      @include breakpoint(tablet-p, min) {
        font-size: 1.8rem;
      }
    }
  }
  .logo {
    &.after {
      width: 40px;
      height: 3px;
    }
  }
  .site__name,
  .site__position {
    font-weight: 500;
    @include breakpoint(mobile, min) {
      font-weight: 400;
    }
    transition: font-size 0.3s linear;
  }
  .site__name {
    font-size: 1.4rem;
    @include breakpoint(mobile, min) {
      font-size: 1.75rem;
    }
    @include breakpoint(tablet-p, min) {
      font-size: 2rem;
    }
  }
  .site__position {
    font-size: 1.6rem;
    @include breakpoint(mobile, min) {
      font-size: 2rem;
    }
    @include breakpoint(tablet-p, min) {
      font-size: 2.4rem;
    }
  }
}

#float_menu {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 99;
  [data-toggle-color-mode],
  [data-menu-toggle] {
    display: inline-block;
    vertical-align: middle;
    background: none;
    border: none;
    cursor: pointer;
  }
  svg {
    width: 26px;
    height: 26px;
  }
  [data-color-mode='dark'] & {
    #svg_moon {
      display: none;
    }
  }
  [data-color-mode='light'] & {
    #svg_sun {
      display: none;
    }
  }
  #svg_sun *,
  #svg_moon * {
    fill: var(--c-btn-bg);
  }
}

.section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:not(#header) {
    padding-top: 86px;
    margin-top: -87px;
  }
  @include breakpoint(test, min) {
    &:not(#header) {
      padding-top: 0;
    }
  }
  &.section--fh {
    height: 100vh;
  }
  .section_inner {
    display: flex;
    flex-wrap: wrap;
    flex: auto;
    max-width: 100%;
    width: 100vw;
    &.section--center--x {
      justify-content: center;
      .section_content {
        width: 800px;
        max-width: 800px;
      }
    }
    &.section--center--y {
      @include breakpoint(tablet-p, min) {
        align-items: center;
      }
    }
  }
  .section_content {
    padding-top: 30px;
    @include breakpoint(tablet-p, min) {
      padding: 55px 0;
    }
    @include breakpoint(tablet-l, min) {
      padding: 70px 0;
    }
    .section_cta {
      max-width: 1080px;
      font-size: 2rem;
      a {
        color: var(--c-accent);
        text-decoration: none;
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
  .section_titre {
    margin-top: 0;
    margin-bottom: 4.5rem;
    font-size: 2.8rem;
    font-weight: 200;
    text-align: center;
    @include breakpoint(tablet-p, min) {
      margin-bottom: 6rem;
      font-size: 3.2rem;
    }
    @include breakpoint(tablet-l, min) {
      margin-bottom: 10rem;
      font-size: 4.6rem;
    }
    > span {
      position: relative;
      display: inline-block;
      padding-bottom: 0.5rem;
      &:after {
        content: '';
        position: absolute;
        bottom: -0.5rem;
        left: 0;
        display: block;
        width: 50px;
        height: 4px;
        background-color: var(--c-accent);
      }
    }
  }
  .section_content {
    width: 100%;
    > * {
      margin-left: auto;
      margin-right: auto;
      max-width: 1240px;
    }
  }
  .section_next {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 12rem;
    @include breakpoint(tablet-l, min) {
      height: 15rem;
    }
    .btn {
      width: 50px;
      height: 50px;
      padding: 0;
      &:hover {
        .btn_inner {
          &:before,
          &:after {
            margin-top: 0;
            background-color: var(--c-accent);
            animation: none;
          }
        }
      }
      .btn_inner {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          display: block;
          width: 18px;
          height: 2px;
          background-color: var(--c-main-bg);
          animation: 1s infinite alternate Bounce;
        }
        &:before {
          left: calc(50% - 6px);
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &:after {
          left: inherit;
          right: calc(50% - 6px);
          transform: translate(50%, -50%) rotate(-45deg);
        }
      }
    }
  }
}

@keyframes Bounce {
  from {
    margin-top: -3px;
  }
  to {
    margin-top: 3px;
  }
}

// Header
#header {
  padding-top: 50px;
}

.site__name,
.site__position {
  margin: 0;
  font-weight: 200;
}

.site__name {
  font-size: 2.3rem;
  color: var(--c-accent);
  text-transform: uppercase;
  @include breakpoint(mobile, min) {
    font-size: 3.2rem;
  }
  @include breakpoint(tablet-p, min) {
    font-size: 3.6rem;
  }
}

.site__position {
  font-size: 2.6rem;
  font-weight: 200;
  @include breakpoint(mobile, min) {
    font-size: 4rem;
  }
  @include breakpoint(tablet-p, min) {
    font-size: 4.4rem;
  }
}

.site__presentation {
  margin-top: 3.6rem;
  min-height: 55px;
  font-size: 1.9rem;
  line-height: 1.4;
}

// Objectifs
.objectifs {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  .objectif {
    padding: 0 2rem;
    text-align: center;
    @include breakpoint(tablet-p, max) {
      margin-bottom: 2rem;
    }
    @include breakpoint(tablet-p, min) {
      width: 33.33%;
      flex-basis: 33.33%;
    }
  }
  .objectif__icon {
    display: block;
    max-width: 100%;
    width: 6rem;
    height: auto;
    margin: 0 auto;
    @include breakpoint(tablet-l, min) {
      width: 10rem;
    }
    .objectif__icon__svg * {
      fill: var(--c-btn-bg);
      stroke: transparent;
    }
  }
  .objectif__titre {
    font-size: 1.7rem;
    text-transform: uppercase;
    @include breakpoint(tablet-l, min) {
      font-size: 2rem;
    }
  }
}

#contact {
  .section_titre {
    margin-bottom: 5.5rem;
  }
  .section_content {
    text-align: center;
    padding-bottom: 6rem;
    .contact_cta {
      max-width: 770px;
    }
    .btn_icon {
      width: 40px;
      height: 40px;
    }
  }
}
