.btn,
.btn_icon {
  position: relative;
  text-decoration: none;
  z-index: 0;
  &:before {
    content: '';
    background-color: var(--c-btn-bg);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 30px;
    transition: width 0.3s linear, height 0.3s linear, opacity 0.3s linear;
    z-index: -1;
  }
  &:hover {
    &:before {
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      opacity: 1;
    }
  }
  &.btn--reverse {
    &:before {
      background-color: var(--c-btn);
    }
  }
}

.btn {
  background-color: var(--c-btn-bg);
  color: var(--c-btn);
  display: inline-block;
  border: 2px solid transparent;
  padding: 15px 20px;
  border-radius: 30px;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  &.btn--small {
    font-size: 1.2rem;
    padding: 7px 12px;
    border-radius: 14px;
  }
  &.btn--reverse {
    background-color: var(--c-btn);
    color: var(--c-btn-bg);
  }
}

.btn_icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  + .btn_icon {
    margin-left: 4px;
  }
  svg {
    position: relative;
    z-index: 1;
    > * {
      fill: var(--c-btn-bg);
    }
  }
  &:after {
    content: '';
    background-color: var(--c-btn);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    transition: width 0.3s linear, height 0.3s linear, opacity 0.3s linear;
    z-index: -1;
  }
  &:before {
    border-radius: 3px;
    z-index: -2;
  }
  &:hover {
    &:before {
      width: calc(100% + 3px);
      height: calc(100% + 3px);
      opacity: 1;
    }
    border-color: var(--c-btn-bg);
  }
}
