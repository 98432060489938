#skills {
  margin-bottom: 4rem;
  max-width: 1080px;
  .skill {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0.1rem;
    color: var(--c-main-bg);
    width: 12.5%;
    .skill_inner {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      padding: 1.9rem 1rem 1rem;
      background-color: var(--c-main);
      flex-grow: 1;
      width: 100%;
      height: 100%;
    }
    &:after {
      content: attr(data-skill-type);
      position: absolute;
      top: 0.1rem;
      left: 0.1rem;
      display: inline-block;
      padding: 0.3rem 1rem;
      color: var(--c-reverse);
      background-color: var(--c-accent);
      border-radius: 0 0 0.6rem 0;
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: uppercase;
    }
    &[data-skill-power='1'] {
      width: 50%;
      height: 60px;
      @include breakpoint(tablet-l, min) {
        width: 12.5%;
      }
    }
    &[data-skill-power='2'] {
      width: 50%;
      height: 60px;
      @include breakpoint(tablet-l, min) {
        width: 25%;
        height: 120px;
      }
    }
    &[data-skill-power='3'] {
      width: 100%;
      height: 115px;
      @include breakpoint(tablet-l, min) {
        width: 50%;
        height: 240px;
      }
    }
    .skill_name {
      display: block;
      width: 90%;
      height: 90%;
    }
    &[data-skill-power='1'] .skill_name {
      font-weight: 300;
    }
    &[data-skill-power='2'] .skill_name {
      font-weight: 400;
    }
    &[data-skill-power='3'] .skill_name {
      font-weight: 500;
    }
  }
}
.grid_sizer {
  width: 12.5%;
}
