#reas {
  position: relative;
  @include breakpoint(tablet-p, min) {
    padding: 0 33px;
    max-width: calc(1240px + 66px);
  }
  .slider_ctn {
    @include breakpoint(tablet-p, max) {
      margin-bottom: 50px;
    }
  }
}

.rea {
  width: 100%;
  .rea_inner {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    background-color: var(--c-main);
    color: var(--c-main-bg);
  }
  .rea_infos {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    order: 2;
    width: 100%;
    flex-basis: 100%;
    padding: 2.5rem;
    @include breakpoint(tablet-p, min) {
      padding: 2.5rem 0 2.5rem 2.5rem;
      order: 1;
      width: 50%;
      flex-basis: 50%;
    }
    @include breakpoint(tablet-l, min) {
      width: 55%;
      flex-basis: 55%;
    }
    .rea_name {
      margin-top: 0;
      margin-bottom: 1.2rem;
      text-transform: uppercase;
      font-size: 2.4rem;
      font-weight: 500;
    }
    .rea_link {
      align-self: flex-end;
      margin-top: 1.5rem;
    }
  }
  .rea_images {
    order: 1;
    width: 100%;
    flex-basis: 100%;
    padding: 2.5rem 2.5rem 0 2.5rem;
    @include breakpoint(tablet-p, min) {
      order: 2;
      width: 50%;
      flex-basis: 50%;
      padding: 2.5rem;
    }
    @include breakpoint(tablet-l, min) {
      width: 45%;
      flex-basis: 45%;
    }
    .img_ctn {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: opacity 0.3s linear;
        cursor: zoom-in;
      }
      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }
    .rea_thumb {
      display: block;
      max-width: 100%;
      height: auto;
      border: 2px solid var(--c-dark);
    }
    .rea_zoom {
      position: absolute;
      bottom: 10px;
      right: 10px;
      display: block;
      width: 45px;
      height: 45px;
      padding: 0.5rem;
      background-color: rgba(255, 255, 255, 0.5);
      border: 2px solid var(--c-dark);
      border-radius: 5px;
      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.slider_nav_item {
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  @include breakpoint(tablet-p, max) {
    bottom: -50px;
  }
  @include breakpoint(tablet-p, min) {
    top: 50%;
    transform: translateY(-50%);
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 18px;
    display: block;
    width: 18px;
    height: 2px;
    background-color: var(--c-main);
    @include breakpoint(tablet-p, min) {
      left: 30px;
    }
  }
  &:before {
    top: 14px;
    transform: translateX(-50%) rotate(-45deg);
  }
  &:after {
    top: 26px;
    transform: translateX(-50%) rotate(45deg);
  }
  &[data-direction='prev'] {
    @include breakpoint(tablet-p, max) {
      left: calc(50% - 20px);
      transform: translateX(-50%);
    }
    @include breakpoint(tablet-p, min) {
      left: -55px;
    }
  }
  &[data-direction='next'] {
    @include breakpoint(tablet-p, max) {
      right: calc(50% - 20px);
      transform: translateX(50%) scale(-1, 1);
    }
    @include breakpoint(tablet-p, min) {
      right: -55px;
      transform: translateY(-50%) scale(-1, 1);
    }
  }
  &:hover {
    &:before,
    &:after {
      background-color: var(--c-accent);
    }
  }
  &.disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
}
